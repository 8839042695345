






import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import ExtraDataMap from './filterModelsMap';

// TODO: extend Base Class for DRY

@Component
export default class FormBar extends Vue {
  @Prop({ required: true }) fields!: string[];

  data: ExtraDataMap = {
    geojsonField: '',
  };

  @Watch('data', { deep: true })
  onDataChange(data: any): void {
    this.$emit('dataChange', data);
  }
}
